var exports = {};

exports = function (hljs) {
  // whitespaces: space, tab, formfeed
  var WS0 = "[ \\t\\f]*";
  var WS1 = "[ \\t\\f]+"; // delimiter

  var DELIM = "(" + WS0 + "[:=]" + WS0 + "|" + WS1 + ")";
  var KEY_ALPHANUM = "([^\\\\\\W:= \\t\\f\\n]|\\\\.)+";
  var KEY_OTHER = "([^\\\\:= \\t\\f\\n]|\\\\.)+";
  var DELIM_AND_VALUE = {
    // skip DELIM
    end: DELIM,
    relevance: 0,
    starts: {
      // value: everything until end of line (again, taking into account backslashes)
      className: "string",
      end: /$/,
      relevance: 0,
      contains: [{
        begin: "\\\\\\n"
      }]
    }
  };
  return {
    case_insensitive: true,
    illegal: /\S/,
    contains: [hljs.COMMENT("^\\s*[!#]", "$"), // key: everything until whitespace or = or : (taking into account backslashes)
    // case of a "normal" key
    {
      begin: KEY_ALPHANUM + DELIM,
      returnBegin: true,
      contains: [{
        className: "attr",
        begin: KEY_ALPHANUM,
        endsParent: true,
        relevance: 0
      }],
      starts: DELIM_AND_VALUE
    }, // case of key containing non-alphanumeric chars => relevance = 0
    {
      begin: KEY_OTHER + DELIM,
      returnBegin: true,
      relevance: 0,
      contains: [{
        className: "meta",
        begin: KEY_OTHER,
        endsParent: true,
        relevance: 0
      }],
      starts: DELIM_AND_VALUE
    }, // case of an empty key
    {
      className: "attr",
      relevance: 0,
      begin: KEY_OTHER + WS0 + "$"
    }]
  };
};

export default exports;